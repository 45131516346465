import { library,dom } from '@fortawesome/fontawesome-svg-core';

import {
    faFacebookF,
    faTwitter,
    faLinkedinIn,
    faInstagram,
    faYoutube,
    faMedium,
    faWhatsapp
} from '@fortawesome/free-brands-svg-icons';
import {
    faCalendar,
    faChevronRight,
    faChevronLeft,
    faTag,
    faEnvelope,
    faPhone,
} from '@fortawesome/pro-solid-svg-icons';

//import {} from '@fortawesome/pro-regular-svg-icons';
// import {} from '@fortawesome/pro-light-svg-icons';
// import {} from '@fortawesome/pro-duotone-svg-icons';
library.add(
    faFacebookF,
    faTwitter,
    faLinkedinIn,
    faInstagram,
    faYoutube,
    faMedium,
    faWhatsapp,
    faCalendar,
    faChevronRight,
    faChevronLeft,
    faTag,
    faEnvelope,
    faPhone,
);

dom.watch();
