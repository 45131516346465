function loadAnimation(elementSelector, chunkName) {
    const element = document.querySelector(elementSelector);
    if (element) {
        import(/* webpackChunkName: "[request]" */ `./anim/${chunkName}`).then((module) => {
            module.default();
        });
    }
}

document.addEventListener("DOMContentLoaded", function () {
    loadAnimation(".banner-svg", "banner-svg");
    loadAnimation(".anim-services", "anim-services");
    loadAnimation(".arc-svg", "arc-svg");
    loadAnimation(".anim-book", "anim-book");
    loadAnimation(".anim-plan", "anim-plan");
    loadAnimation(".anim-tel", "anim-tel");
    loadAnimation(".devweb-svg", "devweb-svg");
    loadAnimation(".icon-1", "icon1");
    loadAnimation(".icon-2", "icon2");
    loadAnimation(".icon-3", "icon3");
});
