document.addEventListener("DOMContentLoaded", function(event) {
    if(document.getElementById('data-wadrgpd')) {

        let days = 180;
        let dataRGPD = JSON.parse(document.getElementById('data-wadrgpd').innerHTML);

        let state = dataRGPD.state;
        let cookieName = dataRGPD.cookieName

        let acceptCookie = document.querySelector(".wad-accept-cookie");
        let rejectCookie = document.querySelector(".wad-reject-cookie");

        let modalRgpd = document.querySelector('#modal-rgpd');
        if (modalRgpd){
            modalRgpd.addEventListener("show.bs.modal",function(){
                let url = this.getAttribute('data-content');
                fetch(url)
                    .then((response) => response.text())
                    .then((data) => document.querySelector('#modal-rgpd-body').innerHTML = data)
                    .then(() => {
                        let buttonsSave = document.querySelectorAll(".wad-save-cookie");
                        for (const button of buttonsSave) {
                            button.addEventListener('click', (event) => {
                                console.log(state);
                                state.answer = true;
                                createCookie(cookieName, JSON.stringify(state), days);
                                hideCookieBanner();
                                getScripts(dataRGPD.list, state);
                            });
                        }

                        //Accept One Cookie
                        let buttonsState = document.querySelectorAll(".wad-state-cookie");
                        for (const button of buttonsState) {
                            button.addEventListener('click', (event) => {
                                let cookieName = event.target.dataset.name;
                                state[cookieName] = event.target.checked;
                                console.log(state);
                            });
                        }
                    });
            })
        }

        // Accept All Cookie
        if(acceptCookie) {
            acceptCookie.addEventListener("click", (event) => {
                rejectCookie.classList.remove("disabled");
                acceptCookie.classList.remove("disabled");
                hideCookieBanner();

                //Il faut mettre tous les cookies à true;
                for(let variable in state) {
                    state[variable] = true;
                }

                //On set le cookie
                createCookie(cookieName,JSON.stringify(state),days);

                //Il faut lancer les scripts qui sont à true
                getScripts(dataRGPD.list,state);
            });
        }

        if(rejectCookie) {
            // Reject All Cookie
            rejectCookie.addEventListener("click", (event) => {
                rejectCookie.classList.add("disabled");
                acceptCookie.classList.remove("disabled");
                hideCookieBanner();
                //Il faut mettre tous les cookies à false;
                for (let variable in state) {
                    state[variable] = false;
                }
                //On set le cookie
                createCookie(cookieName, JSON.stringify(state), days);
            });
        }

        let buttonsSave = document.querySelectorAll(".wad-save-cookie");
        for (const button of buttonsSave) {
            button.addEventListener('click', (event) => {
                console.log(state);
                state.answer = true;
                createCookie(cookieName, JSON.stringify(state), days);
                hideCookieBanner();
                getScripts(dataRGPD.list, state);
            });
        }


        //Accept One Cookie
        let buttonsState = document.querySelectorAll(".wad-state-cookie");
        for (const button of buttonsState) {
            button.addEventListener('click', (event) => {
                let cookieName = event.target.dataset.name;
                state[cookieName] = event.target.checked;
                console.log(state);
            });
        }

        if (window.doNotTrack || navigator.doNotTrack || navigator.msDoNotTrack || ('external' in window && 'msTrackingProtectionEnabled' in window.external)) {
            // The browser supports Do Not Track!
            if (window.doNotTrack == "1" || window.doNotTrack == 1 || navigator.doNotTrack == "yes" || navigator.doNotTrack == "1" || navigator.msDoNotTrack == "1" || ('external' in window && 'msTrackingProtectionEnabled' in window.external && window.external.msTrackingProtectionEnabled())) {
                hideCookieBanner();
                return;
            }
        }

        let consentCookie = readCookie(cookieName);
        if(consentCookie == null) {
            showCookieBanner();
        } else {
            hideCookieBanner();
            let consentObject = JSON.parse(consentCookie);
            if(consentObject.answer == 1) {
                //Lancer les scripts qui sont à true
                getScripts(dataRGPD.list,state);
            }
        }
    } else {
        console.error('RGPD - #data-wadrgpd not found');
        hideCookieBanner();
    }
});

function getScripts(list,state) {
    for(let cptScript in list) {
        if(state[list[cptScript].name]==true) {
            for(let srcScript in list[cptScript].scripts) {
                getScript(list[cptScript].scripts[srcScript]);
            }
        }
    }
}
// SRC : https://stackoverflow.com/a/61903296
const getScript = url => new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = url
    script.async = true

    script.onerror = reject

    script.onload = script.onreadystatechange = function() {
        const loadState = this.readyState

        if (loadState && loadState !== 'loaded' && loadState !== 'complete') return

        script.onload = script.onreadystatechange = null

        resolve()
    }

    document.head.appendChild(script)
})

function showCookieBanner() {
    let bannerCookie = document.querySelector(".wad-banner-cookie");
    if(bannerCookie) {
        bannerCookie.classList.remove("d-none","hidden");
    }
}

function hideCookieBanner() {
    let bannerCookie = document.querySelector(".wad-banner-cookie");
    if(bannerCookie) {
        bannerCookie.classList.add("d-none","hidden");
    }
}


//https://www.quirksmode.org/js/cookies.html
function createCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+date.toGMTString();
    } else {
        var expires = "";
    }

    document.cookie = name+"="+value+expires+"; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') {
            c = c.substring(1,c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length,c.length);
        }
    }
    return null;
}
