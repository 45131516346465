import { gsap, ScrollTrigger,MorphSVGPlugin,SplitText } from './anim/gsap-config';

// LOADING
window.addEventListener('load',function(){
    document.querySelector('.loading-screen').classList.add('loaded');
})

document.addEventListener("DOMContentLoaded", function() {
/*
    let modalRgpd = document.querySelector('#modal-rgpd');
    if (modalRgpd){
        modalRgpd.addEventListener("show.bs.modal",function(){
            let url = this.getAttribute('data-content');
            fetch(url)
                .then((response) => response.text())
                .then((data) => document.querySelector('#modal-rgpd-body').innerHTML = data);
        })
    }
*/
    // GSAP
    gsap.registerPlugin(ScrollTrigger,MorphSVGPlugin,SplitText); //,

    // NAVBAR BURGER
    let navburger = document.querySelector('#nav-burger');
    if(navburger) {
        navburger.addEventListener('click', function () {
            toggleMenu();
        });
    }

    // WAVE ANIMATION
    gsap.to("#wave", {
        duration: 4,
        morphSVG: "#wave2",
        ease: "power1.inOut",
        yoyo: true,
        repeat: -1
    });


    // NAVBAR MENU
    let navbarmenu = document.querySelector('.navbar-menu');
    if(navbarmenu && navbarmenu.classList.contains('deployed')){
        navbarmenu.classList.add('no-transition');
        navbarmenu.classList.remove('deployed');
    }

    // NAVBAR BURGER
    if(navburger && navburger.classList.contains('open')){
        navburger.querySelector('span').classList.add('no-transition');
        navburger.classList.remove('open');
    }

    // NAVBAR DEPLOYED
    let navbar = document.querySelector('.navbar');
    if(document.querySelector('#data-page')){
        navbar.classList.remove('navbar-deployed');
    }else if(!navbar.classList.contains('navbar-deployed')){
        navbar.classList.add('navbar-deployed');
    }

    /* ANIMATIONS */

    const pictos = gsap.utils.toArray('.picto');

    pictos.forEach((box) => {
        const anim = gsap.fromTo(box, {autoAlpha: 0}, {duration: 1.5, autoAlpha: 1});
        ScrollTrigger.create({
            trigger: box,
            animation: anim,
            toggleActions: 'play none complete none',
        });
    });

    const icons = gsap.utils.toArray('.icon');

    icons.forEach((box) => {
        const anim = gsap.fromTo(box, {autoAlpha: 0}, {duration: 1, autoAlpha: 1});
        ScrollTrigger.create({
            trigger: box,
            animation: anim,
            toggleActions: 'play none complete none',
        });
    });

    // GOOD WAVE ANIMATION
    if(document.querySelector('.good-wave')) {
        gsap.to("#good-wave", {
            duration: 4,
            morphSVG: "#good-wave2",
            ease: "power1.inOut",
            yoyo: true,
            repeat: -1
        });
    }

    // SERVICES WAVE ANIMATION
    if(document.querySelector('.services-wave')) {
        gsap.to("#services-wave11", {
            duration: 3,
            morphSVG: "#services-wave21",
            ease: "power1.inOut",
            yoyo: true,
            repeat: -1
        });
        gsap.to("#services-wave12", {
            duration: 4,
            morphSVG: "#services-wave22",
            ease: "power1.inOut",
            yoyo: true,
            repeat: -1
        });
        gsap.to("#services-wave13", {
            duration: 4,
            morphSVG: "#services-wave23",
            ease: "power1.inOut",
            yoyo: true,
            repeat: -1
        });
    }

    // Animation Job
    const macbooks = gsap.utils.toArray('.macbook');
    macbooks.forEach( macbook => {
        let xMacbook = (macbook.parentElement.classList.contains('job-slide-reverse')) ? (macbook.clientWidth/2) : (-macbook.clientWidth/2);
        gsap.from(macbook, {
            x: xMacbook , duration: 1,
            scrollTrigger: {
                trigger: macbook,
                scrub: true,
                start: "top bottom",
                end: "top top",
            }
        });
    });

    const ipads = gsap.utils.toArray('.ipad');
    ipads.forEach( ipad => {
        let xIpad = (ipad.parentElement.classList.contains('job-slide-reverse')) ? (ipad.clientWidth*3) : (-ipad.clientWidth*3);

        gsap.from(ipad, {
            x: xIpad,
            duration: 0.8,
            scrollTrigger: {
                trigger: ipad,
                scrub: true,
                start: "top bottom",
                end: "top top"
            }
        });

    });


    // SPLIT TEXT
    document.querySelectorAll('.split-p').forEach(function(el){
        let split = new SplitText(el, {type: "chars"});
        gsap.from(split.chars,{
            y: '100%',
            stagger: .1,
            duration: 0.8,
            ease: "power1.Out",
            scrollTrigger: {
                trigger: el,
                toggleActions: 'play reverse play reverse', // onEnter, onLeave, onEnterBack, onLeaveBack
            }
        });
    })


    document.querySelectorAll(".btn-scroll").forEach((btn) => {
        btn.addEventListener("click", function() {
            document.querySelector(btn.dataset.target).scrollIntoView();
        });
    });
});

function toggleMenu(){
    // Prevent no transitions
    let navbarmenu = document.querySelector('.navbar-menu')
    if(navbarmenu.classList.contains('no-transition')){
        navbarmenu.classList.remove('no-transition');
    }
    let navburger = document.querySelector('#nav-burger');
    let navburgerspan = document.querySelector('#nav-burger span');
    if(navburgerspan.classList.contains('no-transition')) {
        navburgerspan.classList.remove('no-transition');
    }

    // Toggle
    navburger.classList.toggle('open');
    navbarmenu.classList.toggle('deployed');
}
