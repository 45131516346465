import './_credit';
import './v-withoutScollSmooth';
//import './anim-gsap';
//import './anim/gsap-config';
import './anim';


// import './v-scrollSmooth';
/* BOOTSTRAP */
//import 'bootstrap/js/dist/alert';
//import 'bootstrap/js/dist/button';
//import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
//import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
//import 'bootstrap/js/dist/offcanvas';
//import 'bootstrap/js/dist/popover';
//import 'bootstrap/js/dist/scrollspy';
//import 'bootstrap/js/dist/tab';
//import 'bootstrap/js/dist/toast';
//import 'bootstrap/js/dist/tooltip';
//import * as Popper from '@popperjs/core';

/* FONT AWEWSOME */
import'./font-awesome';
import './wad-rgpd';

/* WOW */
// import {WOW} from "wowjs";

/* TinySlider */
//import {tns} from 'tiny-slider';

/* WOW */
// document.addEventListener('DOMContentLoaded', function () {
//
//     let wow = new WOW({
//         live: false,
//         animateClass: 'animate__animated'
//     });
//     wow.init();
// });
// LOADING

