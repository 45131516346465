(function WadCredit() {
    let userAgent = navigator.userAgent.toLowerCase();
    let supported = /(chrome|firefox|safari)/;

    if (supported.test(userAgent.toLowerCase())) {
        let bgMsg = [
            'padding: 16px 0',
            'background-color: #051a3a',
            'color: #ffffff',
            'font-family: Roboto',
        ].join(';');

        let heart = [
            'padding: 16px 8px',
            'background-color: #051a3a',
            'color: #00c0ff',
            'font-family: Roboto',
        ].join(';');

        let bgUrl = [
            'padding: 16px 8px',
            'background-color: #00c0ff',
            'color: #ffffff',
            'font-family: Roboto',
        ].join(';');

        let spacer = [
            'background-color: transparent',
        ].join(';');

        let msg = '\n\n %c ❤ %c Développé avec passion par WEBADEV %c ❤ %c www.webadev.com %c \n\n\n';

        console.log(msg, heart, bgMsg, heart, bgUrl, spacer);
    } else if (window.console) {
        console.log('Développé avec passion par WEBADEV - https://www.webadev.com');
    }
}());
